.dialog-edit {
    .input-fields {
        width: 100%;
        > div {
            margin: 0 0 10px 0;
            padding: 10px;
            display: flex;
            flex-direction: column;
            background: #eaf1f5;
        }
    }
    label {
        margin-bottom: 5px;
    }
    .p-dialog-footer {
        text-align: center;
        .p-button {
            margin: .6rem .3rem 0 .3rem;
            min-width: 10rem;
            border: 1px solid #007bff;
        }
    }
}

.p-tabview {
    .p-tabview-nav {
        li {
            margin-right: 0.2rem !important;
        }
    }
}

.p-datatable {
    .p-sortable-column.p-highlight {
        background: var(--blue-50) !important;
    }
}
