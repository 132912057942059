.main-wrapper {
  @media screen and (min-width: 768px) {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr;
    grid-template-columns: 320px 1fr;
    overflow-y: hidden;
    grid-template-areas:
      "sidebar header"
      "sidebar content";
    &.sidebar-off {
      grid-template-columns: auto 1fr;
    }
    header {
      grid-area: header;
    }
    .sidebar {
      grid-area: sidebar;
    }
    .content-wrapper {
      grid-area: content;
    }
  }
}

.content-wrapper {
  background: #f7f8f9;
  padding: calc(1em + 1vw);
  padding-bottom: calc(2em + 1vw);
  @media screen and (min-width: 768px) {
    height: calc(100vh - 110px);
    overflow-y: scroll;
  }
  .p-datatable {
    padding-bottom: 45px;
  }
  .err-server {
    margin-bottom: 15px;
  }
  .note-digits {
    color: black;
    font-size: 1em;
    font-weight: 200;
    margin-bottom: 15px;
  }
  .err-calc {
    margin-bottom: 15px;
    > div {
      margin-bottom: 5px;
      &:last-child {
        margin: 0;
      }
    }
  }
  form {
    margin-bottom: 45px;
    .btn-actions {
      width: max-content;
      display: flex;
      margin-top: 30px;
      > :nth-child(2) {
        margin-left: 8px;
      }
    }
    .custom-select {
      margin-bottom: 15px;
    }
    .form-fields {
      .input-fields {
        display: flex;
        flex-direction: column;
        label.required-true {
          &:after {
            content: "*";
          }
        }
        > div {
          margin-bottom: 15px;
          label {
            display: block;
            margin-bottom: 3px;
          }
        }
      }
      .err-msg {
        margin: 8px 0;
      }
    }
  }
}

.breadcrum {
  line-height: 1.2;
  margin-bottom: 15px;
  a {
    font-size: 0.8em;
    font-weight: 400;
    display: flex;
    flex-direction: row;
  }
}

form .form-container {
  display: grid;
  grid-gap: 30px;
  background: white;
  padding: 15px;
  @media screen and (min-width: 920px) {
    grid-template-columns: auto 300px;
  }
  h2 {
    margin-top: 0;
  }
  .calc-fields {
    h3 {
      svg {
        font-size: 0.75em;
      }
    }
    border-radius: 5px;
    background: #283949;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    .calc-container {
      padding: 15px;
      @media screen and (min-width: 920px) {
        position: sticky;
        top: 0;
      }
      h3 {
        font-weight: 800;
        text-align: center;
        margin: 15px 0 30px 0;
      }
      .note {
        font-size: .86em;
        line-height: 1.3;
        margin: 16px;
        padding-top: 8px;
        text-align: center;
        display: flex;
        border-top: 1px dotted rgba($color: white, $alpha: 0.2);
      }
      .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 80px;
        span {
          margin-bottom: 10px;
        }
      }
      .computed-fields {
        .input-text {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          label {
            text-align: center;
            font-weight: 600;
            margin-bottom: 5px;
            font-size: 0.75em;
            line-height: 1;
          }
          input {
            border-radius: 10px;
            border: 2px solid #2f2f2f;
            font-size: 1.2em;
            color: black;
            font-weight: 800;
            width: 90%;
            align-self: center;
            text-align: center;
          }
        }
      }
      .buttons {
        margin-top: 45px;
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-gap: 5px;
      }
    }
  }
  .form-fields {
    .input-fields {
      display: grid;
      grid-gap: 10px;
      @media screen and (min-width: 620px) {
        grid-template-columns: 1fr 1fr;
      }
      > div {
        margin: 0;
        padding: 15px;
        display: flex;
        flex-direction: column;
        background: #eaf1f5;
        @media screen and (max-width: 620px) {
          &:nth-child(2n) {
            background: var(--secondary);
          }
          &:nth-child(2n + 1) {
            background: #eaf1f5;
          }
        }

        label {
          display: block;
          margin-bottom: 3px;
        }
      }
    }
    .submit-btn {
      margin-top: 10px;
    }
  }
}
