.top-menu {
    color: white;

    @media screen and (max-width: 768px) {
        display: none;
    }

    ul {
        display: flex;
        align-items: center;

        li {
            display: inherit;
            align-items: inherit;

            &.finca {

                height: 34px;
                font-size: .86em;
                font-weight: 600;
                margin-right: 15px;
                padding: 2px 16px 2px 8px;
                color: black;
                border-radius: 5px;
                font-size: 0.8em;

                i {
                    margin-right: 8px;
                }

                &.empty {
                    background: yellow;
                }

                &.selected {
                    font-size: .86em;
                    background: white;
                    color: black;
                }
            }
        }
    }
}
