header {
    .container {
        margin: 0 auto;
        width: 1200px;
    }
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4%;

    .p-inputgroup {
        max-width: 250px;
    }

    h1 {
        border-bottom: none;
        text-align: center;
        margin-bottom: 15px;
    }

    h2 {
        margin: 0 0 60px 0;
        font-size: 1.14em;
        font-weight: 400;

        &::after {
            display: none;
        }
    }

    footer {
        font-size: 0.78em;
        margin-top: 45px;
        text-align: center;

        h3 {
            display: block;
            font-weight: bold;
            font-size: .93em;
            margin-bottom: 10px;
        }

        div {
            margin-bottom: 8px;

            &.bottom {
                padding-top: 15px;
                margin-top: 15px;
                border-top: 1px dotted lightgrey;
            }
        }
    }

    .reconocimientos-modal {
        margin: 30px 0;
    }

    button.p-button {
        background-color: var(--main-blue);
        margin: 0 auto;
    }

    button.p-button-link {
        background-color: white !important;
        color: #126aaa !important;
        border: none !important;

        span {
            font-size: .86em;
        }
    }

    button.p-button-link:enabled:hover {
        span {
            text-decoration: underline !important;
        }
    }

    .err-server {
        margin-bottom: 15px;
    }

    .p-inputgroup {
        margin-bottom: 8px;
    }

    .btn {
        margin-top: 15px;
    }

    .login-modal {
        .p-dialog-content {
            padding: 15px 20px 25px;
        }
    }
}

.ReactModal__Content {
    @media screen and (min-width: 768px) {
        inset: 100px !important;
        padding: 2% 15% !important;
    }

    .modal-content p {
        @media screen and (min-width: 768px) {
            line-height: 1.6;
            margin-bottom: 30px;
        }
    }

    h1 {
        color: var(--main-blue);
        border: none;
        text-align: center;
        text-transform: uppercase;
        margin: 30px 0;

        @media screen and (max-width: 768px) {
            font-size: 1.28em;
        }
    }

    .modal-logos {
        margin: 75px 0 30px 0;

        .main {
            text-align: center;
            margin-bottom: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            img {
                border-style: none;
                max-height: 80px;
                margin: 0.2em;
            }
            > div {
                margin: 0.4em;
            }
        }
    }
}
