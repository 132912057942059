.sidebar {
  background: var(--secondary);
  color: #212529;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  flex-flow: column;
  padding: 15px 0;
  .toogle {
    text-align: center;
    button {
      color: inherit;
      background: none;
      border: none;
      border-radius: 0;
      font-size: .86em;
      &:hover {
        color: inherit;
        background: inherit;
      }
    }
    @media screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      z-index: 99999;
    }
  }
  h1 {
    margin: 30px 0 0;
    border-bottom: none;
    font-size: 1.07em;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    padding: 0 20px;
  }
  .legend {
    margin-top: 15px;
    font-weight: 300;
    font-size: 1.07em;
    line-height: 1.4;
    text-align: center;
    span {
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1em;
    }
  }
  .sidebar-menu {
    .p-dropdown {
      margin: 0 15px 15px;
    }
    ul {
      display: flex;
      flex-direction: column;
      margin: 15px 0;
      padding: 0;
      li {
        display: block;
        a {
          color: inherit;
          display: flex;
          padding: 10px 20px;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
          svg {
            color: #53626c;
          }
          &.active {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
  &.expand {
    @media screen and (max-width: 768px) {
      height: 100vh;
      width: 60vw;
      z-index: 9999;
      position: absolute;
      top: 0;
      left: 0;
    }
    .toogle {
      position: relative;
    }
    .sidebar-menu {
      > ul {
        display: flex;
        padding: 15px;
        margin: 20px 15px;
        background: white;
        border-radius: 8px;
      }
    }
  }

  &.collapse {
    h1,
    .legend {
      display: none;
    }
    @media screen and (max-width: 768px) {
      padding: 0;
      .toogle {
        button .pi:before {
          font-size: 1.4em;
          content: "\2630";
        }
      }
    }
    .sidebar-menu {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .sidebar-menu {
      div {
        display: none;
      }
      ul {
        li {
          border-bottom: none;
        }
        a {
          display: flex;
          justify-content: center;
        }
        svg {
          font-size: 1.3em;
        }
        span {
          display: none;
        }
      }
    }
  }
}
