.tabla_results {
    margin-bottom: 2em;
    th {
        text-align: center !important;
        small {
            color: #126aaa;
            font-weight: bold;
        }
        .primeraColumna {
            text-align: left !important;
            margin-left: 1em;
            color: #212529;
            .subtitulo1 {
                margin-left: 1em;
            }
            .subtitulo2 {
                margin-left: 2em;
            }
            small {
                color: #212529;
            }
        }
    }
    td {
        text-align: center !important;
        font-size: .86em !important;
        padding: .80em !important;
    }
    td:first-child {
        text-align: left !important;
    }
    tr.rowConHijos1 {
        background-color: #E8F0F3 !important;
        font-size: 1.2em !important;
        font-weight: bold;
    }
    tr.rowConHijos2 {
        background-color: rgb(244, 244, 248) !important;
        font-weight: bold;
    }
    .noWrap {
        white-space:nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 30%;
    }
    .porcentage {
        color: green !important;
    }
    .botoneratabla2 {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .selectorNivel {
        text-align: center;
        margin-bottom: 0.5em;;
    }
}

.mensaje_no_data {
    width: 60%;
    text-align: center;
    color: var(--yellow-600);
    background-color: var(--secondary);
    font-weight: bold;
    font-size: 1.5em;
    padding: 1.5em;
    border-radius: 8px;
}

.enlaceDescarga {
    cursor: pointer;
    color: rgb(2, 2, 126);
    font-weight: bold;
}
