.ContenedorDeGraficas {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .graficaWrapperTablaA, .graficaWrapperTablaB, .graficaWrapperTabla2 {
        padding: 0 10px 10px 10px;

        .graficaHeader {
            width: 100%;
            background-color: #EFEFEF;
            padding: 10px;
            margin: 0 0 5px 0;
            display: flex;
            justify-content: space-between;
        }
        .grafica {
            width: 100%;
            background-color:#FFFFFF;
            chart {
                width: 100%;
                position: relative;
            }
        }
    }

    .graficaWrapperTablaA, .graficaWrapperTablaB {
        width: 800px;
    }

    .graficaWrapperTabla2 {
        width: 900px;
    }
}

.selectorNivel .p-dropdown .p-dropdown-label {
    width: 450px;
    text-overflow: ellipsis;
}
